import React from "react";

import type { HeadFC } from "gatsby";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import { Box } from "@mui/system";

import HeightIcon from "@mui/icons-material/Height";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ShareIcon from "@mui/icons-material/Share";

import {
  Layout,
  BaseHead,
  RelatedCreationsLists,
  SameCategoryCreationsList,
} from "../../components";
import Theme from "../../components/theme";

const CreationPage = ({ data: { creation, category } }: any) => {
  const { images, tags, key, thumbs, height, width } = creation;
  const mainImage = images[0];
  const image = getImage(mainImage.src);

  const multiImage = images.length > 1;
  const restImage = thumbs.slice(1, 4);

  const html = creation?.description?.childMarkdownRemark?.html || null;

  const canShare = typeof window !== "undefined" && !!navigator?.share;
  // TODO: clean and split
  return (
    <Layout category={category} creation={creation}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Paper sx={{ padding: Theme.spacing(1) }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={multiImage ? 9 : 12}>
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#ffffff",
                    justifyContent: "center",
                  }}
                >
                  {image ? (
                    <GatsbyImage
                      image={image}
                      alt={mainImage.alt}
                      title={mainImage.title}
                    />
                  ) : null}
                </Box>
              </Grid>
              {multiImage ? (
                <Grid item xs={12} md={3} spacing={1}>
                  <Grid container direction={{ xs: "row", md: "column" }}>
                    {restImage.map((thumb) => {
                      const thumbnailImage = getImage(thumb.src);

                      if (!thumbnailImage) {
                        return null;
                      }

                      return (
                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: "flex",
                            backgroundColor: "#ffffff",
                            justifyContent: "center",
                            margin: {
                              xs: Theme.spacing(0, 1, 0, 0),
                              md: Theme.spacing(0, 0, 1, 0),
                            },
                          }}
                        >
                          <GatsbyImage
                            image={thumbnailImage}
                            alt={thumb.alt}
                            title={thumb.title}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper sx={{ padding: Theme.spacing(1) }}>
            <Typography variant="h4" component="h1">
              {creation.name}
            </Typography>

            <Stack direction="row" spacing={1} sx={{ mx: 1, my: 1 }}>
              <Chip
                color="primary"
                label={category.name}
                component="a"
                href={`#${category.key}`}
                clickable
              />
              {tags.map((tag: string) => (
                <Chip
                  label={tag}
                  variant="outlined"
                  component="a"
                  href={`#${tag}`}
                  clickable
                />
              ))}
            </Stack>

            {height || width ? (
              <Stack sx={{ my: 1 }} direction="row" spacing={1}>
                {height ? (
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    title="Hauteur"
                  >
                    <HeightIcon sx={{ mx: 0.5 }} /> {height} cm
                  </Typography>
                ) : null}
                {width ? (
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    title="Longeur"
                  >
                    <HeightIcon sx={{ transform: "rotate(90deg);", mx: 0.5 }} />
                    {width} cm
                  </Typography>
                ) : null}
              </Stack>
            ) : null}

            {html ? (
              <>
                <Divider variant="middle" sx={{ my: 2 }} />
                <Box dangerouslySetInnerHTML={{ __html: html }} />{" "}
              </>
            ) : null}
          </Paper>
          {canShare ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                my: 1,
              }}
            >
              <IconButton
                color="primary"
                title="Partager"
                onClick={() => {
                  navigator
                    .share({
                      url: window.location.href,
                      title: `JB Créations Céramique : ${creation.name}`,
                      text: `JB Créations Céramique : ${creation.name}`,
                      // TODO: description
                    })
                    .catch((error) => {
                      console.error(error);
                    });

                  // @ts-ignore
                  if (window.gtag) {
                    // @ts-ignore
                    window.gtag("event", "share", {
                      event_label: creation.name,
                      event_category: "engagement",
                    });
                  }
                }}
              >
                <ShareIcon />
              </IconButton>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ my: 4 }}>
        En relation
      </Divider>
      <SameCategoryCreationsList creationKey={key} category={category} />
      {tags && tags.length ? (
        <RelatedCreationsLists tags={tags} creationKey={key} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query CreationPage($key: String, $category: String) {
    category(key: { eq: $category }) {
      key
      name
      type
    }
    creation(key: { eq: $key }) {
      key
      name
      height
      width
      description {
        childMarkdownRemark {
          html
        }
      }
      category
      tags
      images {
        alt
        title
        src {
          childImageSharp {
            gatsbyImageData(
              height: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              backgroundColor: "rgba(255,255,255,1)"
            )
          }
        }
      }
      thumbs: images {
        alt
        title
        src {
          childImageSharp {
            gatsbyImageData(
              height: 190
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              backgroundColor: "rgba(255,255,255,1)"
            )
          }
        }
      }
    }
  }
`;

export const Head: HeadFC = ({
  data: {
    creation: { name },
  },
}: any) => <BaseHead title={name} />;

export default CreationPage;
